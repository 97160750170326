import React, { useState, useEffect } from "react";
import { Container, Menu, Image, Dropdown, Icon } from "semantic-ui-react";
import { Link } from "gatsby";
import logo from "../../images/newLogo.png";
import logob from "../../images/newLogo-black.png";

function Header({ page, blackLogo }) {
  const [isOpen, setOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!isOpen);
    setSubMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setShowHeader(currentScrollTop < lastScrollTop || currentScrollTop === 0);
      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <div className={`header-nav ${showHeader ? "show" : "hide"}`}>
      <Menu size="large" style={{ fontWeight: "600" }}>
        <Container style={{ zIndex: '99' }}>
          <Menu.Item position="left" className="brand">
            <Link to="/">
              {blackLogo ? <Image src={logob} style={{ position: 'relative', left: '-50px' }} /> : <Image src={logo} style={{ position: 'relative', left: '-50px' }} />}
            </Link>
          </Menu.Item>
          <Dropdown item text='Products' simple className={`series-dropdown ${page.startsWith("series") ? "active" : ""}`}>
            <Dropdown.Menu>
              <Dropdown item text='Porcelain'>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/looks/marble">Marble Look</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/looks/stone">Stone Look</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/looks/concrete">Concrete Look</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/looks/wood">Wood Look</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/all-porcelain">All Porcelain</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown.Item as={Link} to="/looks/ceramic" className="series-item">Ceramic</Dropdown.Item>
              <Dropdown.Item as={Link} to="/looks/slab" className="series-item">Slab</Dropdown.Item>
              <Dropdown.Item as={Link} to="/looks/natural-stone" className="series-item">Natural Stone</Dropdown.Item>
              <Dropdown.Item as={Link} to="/looks/2cm-outdoor" className="series-item">2cm Outdoor</Dropdown.Item>
              <Dropdown.Item as={Link} to="/looks/clearance" className="series-item">Clearance</Dropdown.Item>
              <Dropdown.Item as={Link} to="/products" className="series-item">All Products</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item className={page === "gallery" ? "active" : ""}>
            <Link to="/gallery">Projects</Link>
          </Menu.Item>
          <Menu.Item className={page === "about" ? "active" : ""}>
            <Link to="/about">About</Link>
          </Menu.Item>
          <Menu.Item className={page === "contact" ? "active" : ""}>
            <Link to="/contact">Contact</Link>
          </Menu.Item>
          <Menu.Item className="search-menu-item">
             <Link to="/products">
             <svg className="svg-icon search-icon" aria-labelledby="title desc" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7"><title id="title">Search Icon</title><desc id="desc">A magnifying glass icon.</desc><g className="search-path" fill="none" stroke="#555"><path strokeLinecap="square" d="M18.5 18.3l-5.4-5.4"/><circle cx="8" cy="8" r="7"/></g></svg>
              Search
            </Link>
          </Menu.Item>
        </Container>
      </Menu>
      {/* Mobile menu and icons */}
      <div className="mobile-header">
        {!isOpen ? (
          <div className="dd-icon">
            <div>
              <Link to="/">
                {blackLogo ? <Image src={logob} /> : <Image src={logo} />}
              </Link>
            </div>
            <Icon onClick={toggleMenu} name="bars" />
          </div>
        ) : (
          <div className="dd-list mobile-menu">
            <div>
              <Link to="/">
                {blackLogo ? <Image src={logob} /> : <Image src={logo} />}
              </Link>
              <Icon onClick={toggleMenu} name="close" className="close-icon" />
            </div>
            <ul>
              <li><Link className={page === "homepage" ? "active" : ""} to="/">Home</Link></li>
              <li onClick={() => setSubMenuOpen(!subMenuOpen)} style={{ cursor: 'pointer' }}>
                Products
                {subMenuOpen && (
                  <ul className="sub-menu">
                    <li><Link to="/all-porcelain">Porcelain</Link></li>
                    <li><Link to="/looks/ceramic">Ceramic</Link></li>
                    <li><Link to="/looks/slab">Slab</Link></li>
                    <li><Link to="/looks/natural-stone">Natural Stone</Link></li>
                    <li><Link to="/looks/2cm-outdoor">2cm Outdoor</Link></li>
                    <li><Link to="/looks/clearance">Clearance</Link></li>
                    <li><Link to="/products">All Products</Link></li>
                  </ul>
                )}
              </li>
              <li><Link className={page === "about" ? "active" : ""} to="/about">About</Link></li>
              <li><Link className={page === "contact" ? "active" : ""} to="/contact">Contact</Link></li>
              <li> <Link className="search-menu-item" to="/products"> Search </Link></li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
