import React, { useEffect, useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import './footer.css'

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          contentfulHomepage {
            conditionOfSales {
              conditionOfSales
            }
            careAndMaintenance {
              careAndMaintenance
            }
            facebookLink {
              facebookLink
            }
            instagramLink {
              instagramLink
            }
          }
        }
      `}
      render={data => {
        const footer = data.contentfulHomepage
        return (
          <div className="footer-container">
            <div className="footer-left">
              <p>© {currentYear} DP Tile and Stone. All rights reserved.</p>
            </div>
            <div className="footer-right">
              <a
                href={footer.conditionOfSales.conditionOfSales}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                Condition of Sale
              </a>
              <a
                href={footer.careAndMaintenance.careAndMaintenance}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                Care and Maintenance
              </a>
              <div className="social-media-icons">
                {footer.facebookLink.facebookLink && (
                  <a
                    href={footer.facebookLink.facebookLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </a>
                )}
                {footer.instagramLink.instagramLink && (
                  <a
                    href={footer.instagramLink.instagramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                )}
                <a
                  href="mailto:shirley@dptile.ca" 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <i className="fa fa-envelope" aria-hidden="true"></i> 
                </a>
              </div>
            </div>
          </div>
        )
      }}
    />
  );
}

export default Footer
